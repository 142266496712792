/* APP COLORS */
$black: #000;
$white: #fff;
$primary-color: #09bed2;//#1baff5;
$secondary-color: #2196f3;
$priority-color: #f11a1a;
$action-color: $secondary-color;
$label-color: #878787;
$disabled-color: #c3c3c3;
$text-color: #121212;
$secondary-text-color: rgba($black, .6);
$accent-color: #a8a8a8;
$body-bg-color: #fafafa;
$header-background-color: #fff;
$header-action-button-color: $accent-color;
$light-text-color: rgba($white, .8);

$border: 1px solid $secondary-text-color;

/* TEXTURES */
$border-radius: .125rem;
$box-shadow: 0 .25rem .375rem rgba(#333, .25);

/* FONTS */
$font-path: '../assets/fonts';
$font-light: 'Avenir Next W01 Light', Arial, sans-serif;
$font-regular: 'AvenirNextLTW01-Regular', Arial, sans-serif;
$font-bold: 'Avenir Next W1G', Arial, sans-serif;
$font-numbers: 'Avenir Next Cyr W00 Regular', Arial, sans-serif;
$font-demi: 'Avenir Next LT W01 Demi', Arial, sans-serif;
$font-ultra-light: 'Avenir Next TW01 UltraLight', Arial,sans-serif;

$custom-typography-styles: (letter-spacing: normal);

/* SCREEN SIZES */
$xxs: 25em; // 400px
$xs: 30em; // 480px
$sm: 37.5em; // 600px
$md: 60em; // 960px
$lg: 80em; // 1280px
$xl: 90em; // 1440px
$ipad: 48em; //768px

/* PH Overrides */
$ph-header-background-color: #fff;
$ph-header-action-button-color: #a8a8a8 !important;
